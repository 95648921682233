<template>
    <div>
        <vs-alert :active="msg.length>0" color="danger">
            zone de saisie <b v-html="msg"></b>
        </vs-alert>
        <div class="vx-row">
            <div class="vx-col md:w-1/2 w-full mt-5">
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full">
                        <span>Prénom</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-input class="w-full" v-model="member.first_name" />
                    </div>
                </div>

                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full">
                        <span>Téléphone</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vue-tel-input class="w-full" v-model="member.phone"></vue-tel-input>
                    </div>
                </div>

                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full">
                        <span>Genre</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                    <div class="vx-row">
                        <div class="vx-col">
                            <vs-radio v-model="member.gender" vs-name="radios1" vs-value="m">Homme</vs-radio>
                        </div>
                         <div  class="vx-col">
                            <vs-radio v-model="member.gender" vs-name="radios1" vs-value="f">Femme</vs-radio>
                        </div>
                         <div  class="vx-col">
                             <vs-radio v-model="member.gender" vs-name="radios1" vs-value="?">Inconnu</vs-radio>
                        </div>

                    </div>
                       
                    </div>
                </div>
            </div>

            <div class="vx-col md:w-1/2 w-full mt-5">
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full">
                        <span>Nom</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-input class="w-full" v-model="member.last_name" />
                    </div>
                </div>

                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full">
                        <span>Email</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-input class="w-full" type="email" v-model="member.email" />
                    </div>
                </div>

                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full">
                        <span>Anniversaire</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-input class="w-full select-large" type="date" v-model="member.birthday" />
                    </div>
                </div>
            </div>
        </div>

        <!--<vs-divider>Adresse</vs-divider>-->
        <br/>

        <div class="vx-row mb-6">
            <div class="vx-col sm:w-1/6 w-full">
                <span>Rue</span>
            </div>
            <div class="vx-col sm:w-5/6 w-full">
                <vs-input class="w-full" v-model="member.address" type="text" />
            </div>
        </div>
        <div class="vx-row">
            <div class="vx-col md:w-1/2 w-full mt-5">
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full">
                        <span>Code postal</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-input class="w-full" v-model="member.postal_code" v-on:click="openCP()" readonly/>
                    </div>
                </div>
            </div>
            <div class="vx-col md:w-1/2 w-full mt-5">
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full">
                        <span>Ville</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-input class="w-full" v-model="member.city" type="text" disabled />
                    </div>
                </div>
            </div>
        </div>


        <div class="vx-row mb-6">
            <div class="vx-col sm:w-1/6 w-full">
                <span>Taille tenue</span>
            </div>
            <div class="vx-col sm:w-5/6 w-full">
                <vs-input class="w-full" v-model="member.vest" maxlength="6" />
            </div>
        </div>
        <br/>

        <template v-if="all" >

            <!--<vs-divider>Type de compte</vs-divider>-->
            <div class="vx-row">
                <div class="vx-col md:w-1/2 w-full mt-5">
                    <div class="vx-row mb-6">
                        <div class="vx-col sm:w-1/3 w-full">
                            <span>Type</span>
                        </div>
                        <div class="vx-col sm:w-2/3 w-full">
                            <vs-select v-model="member.type" class="w-full select-large">
                                <vs-select-item value="e" text="Essai" />
                                <vs-select-item value="m" text="Membre" />
                                <vs-select-item value="c" text="Comptable" />
                                <vs-select-item value="u" text="Employé" />
                                <vs-select-item value="a" text="Admin" />
                                <vs-select-item value="f" text="Franchise" />
                            </vs-select>
                        </div>
                    </div>
                </div>
                <div class="vx-col md:w-1/2 w-full mt-5">
                    <div class="vx-row mb-6">
                        <div class="vx-col sm:w-1/3 w-full">
                            <span>Etat</span>
                        </div>
                        <div class="vx-col sm:w-2/3 w-full">
                            <vs-select v-model="member.active" class="w-full select-large">
                                <vs-select-item value="-1" text="Suspendu" />
                                <vs-select-item value="0" text="Inactif" />
                                <vs-select-item value="1" text="Actif" />
                            </vs-select>
                        </div>
                    </div>
                </div>
            </div>

            <!--<vs-divider>Communication</vs-divider>-->
            <div class="vx-row">
                <div class="vx-col md:w-1/2 w-full mt-5">
                    <div class="vx-row mb-6">
                        <div class="vx-col sm:w-1/3 w-full">
                            <span>SMS commercial</span>
                        </div>
                        <div class="vx-col sm:w-2/3 w-full">
                            <vs-checkbox v-model="member.csms" class="w-full"></vs-checkbox>
                        </div>
                    </div>
                </div>
                <div class="vx-col md:w-1/2 w-full mt-5">
                    <div class="vx-row mb-6">
                        <div class="vx-col sm:w-1/3 w-full">
                            <span>Mail commercial</span>
                        </div>
                        <div class="vx-col sm:w-2/3 w-full">
                            <vs-checkbox v-model="member.newsletter" class="w-full"></vs-checkbox>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <br/>
        <template v-if="member.type!='a' || admin">
            <vs-button color="success" type="filled" class="w-full" v-on:click="update()">Enregistrer</vs-button><br/>
        </template>
        <br/>
        <CPcity ref="CPcity" />
    </div>
</template>

<style lang="scss">

</style>

<script>
import vSelect from 'vue-select'
import Member  from '@/database/models/member'
import Fdate from '@/assets/utils/fdate'

import CPcity from '@/components/member/cp.vue'


function validateEmail(s)
{
    let re = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (re.test(s)) return true
    else return false
}

export default {
    components:{
        vSelect,
        CPcity,
	},
    props: {
        uid:{
            type:String,
            default: '?',
        },
        all:{
            type:Number,
            default: 1,     
        }
    },
    data(){
        return{
            member: {
                first_name:'',
                last_name:'',
                email:'',
                phone:'',
                address:'',
                city:'',
                postal_code:'',
                csms:0,
                newsletter:0,
                vest:'',
                type:'',
            },
            msg : '',
            femme:'',
            homme:'',
            inconnu:'',
            admin:false
        }
    },
    watch:{
        $route (){
        },
    },
    mounted:function()
	{
        this.refresh();
    },
    methods:
    {
        refresh()
        {
            Member.getMemoryClone( this.uid, (mbr)=>
            {
                this.member = mbr
                this.member.birthday = Fdate.getInputDate( this.member.birthday )

                //birthday remove 1970
                let d = new Date( this.member.birthday );
                if(d.getFullYear() == "1970")
                if(d.getMonth() == 0)
                if(d.getDate() == 1)
                if(d.getHours() == 0)
                if(d.getMinutes() == 0)
                    this.member.birthday = null;
            })
            this.RoleAdmin();
        },

        
        
        update()
        {
            //nettoyage phone
            this.member.phone = this.member.phone.replace(/\s/g,'')

            //nettoyage birthday
            //this.member.birthday = (new Date( this.member.birthday )).toString();

            //validate email
            if(this.member.email)
            if(!validateEmail(this.member.email))
            {
                this.msg = 'Email invalide !';
                return;
            }
            
            //update
            Member.update( this.uid, this.member )
            .then(()=>{
                this.$emit('refresh')
            })
            .catch((err)=>{
                let msg = err.message;
                if(msg == "doublons phone")
                    msg = "numéro de téléphone déja utilisé";
                this.msg = 'Mise à jour impossible:<br/>'+msg;
            })
        },
        openCP(){
            this.$refs.CPcity.openPopup( this.member.postal_code,( cp, city )=>{
                this.member.postal_code = cp
                this.member.city = city
            });
        },

        setMsg( msg )
        {
            this.msg = msg;
        },


        RoleAdmin()
        {
            this.admin = false;
                  //Chargement info user
            let uid = this.$srvApi.getLoginUserUid();
            Member.getMemory(uid, (mbr)=>
            {
                this.admin = mbr.type.toLowerCase() == "a";
            });
        },
    }
}

</script>